<template>
  <div class="border-b border-gray-800 px-6 py-4">
    <div class="flex justify-between">
      <div class="flex items-center space-x-2">
        <span class="block text-sm font-semibold text-gray-200">Delivery Options</span>
        <span
          v-if="required.method && messengerDefaultOption.checked && !selectedMessengerChat"
          class="block text-xs text-red-400"
        >
          Select a chat for messenger option
        </span>
        <span v-else-if="required.method" class="block text-xs text-red-400">Select at least one delivery option</span>
      </div>
      <BaseButton size="xs" type="secondary" @press="showModal = true">Add new delivery option</BaseButton>
    </div>
    <div class="flex flex-col py-2">
      <div class="grid max-h-96 grid-cols-3 overflow-y-auto">
        <div v-for="(row, idx) in filteredWebhooks" :key="idx" class="flex items-center self-start">
          <template v-if="row.channel != 'sigdev_messenger'">
            <BaseCheckbox style="padding-left: 0px !important" v-model="row.checked" :id="row.id">
              {{ row.name }}
            </BaseCheckbox>
            <component
              :is="typeof iconMap[row.channel] === typeof {} ? iconMap[row.channel]?.c : iconMap[row.channel]"
              :icon="iconMap[row.channel]?.i"
              class="h-4 w-4 text-gray-300"
            />
          </template>
        </div>
      </div>
      <template v-if="messagingPermission">
        <div class="flex flex-col">
          <BaseCheckbox
            style="padding-left: 0px !important"
            v-model="messengerWebhook.checked"
            :id="messengerWebhook.id"
          >
            {{ messengerWebhook.name }}
            <ChatBubbleLeftRightIcon class="h-4 w-4 text-gray-300" />
          </BaseCheckbox>
          <div v-if="messengerWebhook.checked" class="mt-3 flex rounded text-sm font-medium text-gray-200">
            Select a chat for Messenger
          </div>
        </div>
        <AlertDeliveryMessengerOption
          :checked="messengerWebhook.checked"
          :alert-name="name"
          :selected-alert-type="selectedAlertType"
          :selected-messenger-chat="selectedMessengerChat"
          @set-row="messengerWebhook.checked = $event"
          @set-messenger-data="selectMessengerChat"
        />
      </template>
    </div>
  </div>
  <div class="space-y-2 border-b border-gray-800 px-6 py-4">
    <label class="flex text-sm font-semibold text-gray-200">Alert Duration </label>
    <BaseRadio :options="['Ongoing', 'Once']" v-model="duration" />
  </div>
  <div class="space-y-2 border-b border-gray-800 px-6 py-4">
    <label class="text-sm font-semibold text-gray-200">Expiry Date (Optional)</label>
    <ButtonDatepicker
      @change="
        (date, type) => {
          expiration_date = date;
        }
      "
      class="w-full"
      placeholder="MM/DD/YY"
      format="MM/DD/YY"
      :disabled-future-date="date => date <= new Date()"
      :range="false"
      :default-date="[expiration_date]"
      :key="expiration_date"
    />
  </div>
  <div class="space-y-2 px-6 py-4">
    <div class="flex items-center space-x-2">
      <span class="block text-sm font-semibold text-gray-200"> Alert Name </span>
      <span v-if="required.name" class="block text-xs text-red-400">Name must not be empty</span>
      <span v-else-if="duplicateName" class="block text-xs text-red-400">Name has already been taken</span>
    </div>
    <BaseTextbox placeholder="Alert name" v-model="alertName" />
  </div>
  <AlertsDeliveryModal v-model="showModal" />
</template>

<script setup>
import { onMounted, onBeforeUnmount, ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { formatNumber, titleize, formatSmallNumbers } from '@/composeables/filters';
import { router } from '@inertiajs/vue3';
import useHttp from '@/composeables/http';
import isEmpty from 'lodash/isEmpty';
import { DateTime } from 'luxon';
import { ChatBubbleLeftRightIcon, EnvelopeIcon, ComputerDesktopIcon } from '@heroicons/vue/20/solid';
import AlertDeliveryMessengerOption from '@/components/alerts/delivery/AlertDeliveryMessengerOption.vue';
import AlertsDeliveryModal from '@/components/alerts/delivery/AlertsDeliveryModal.vue';
import useEmitter from '@/composeables/emitter';
import CustomIcons from '@/components/CustomIcons.vue';
import ButtonDatepicker from '@/components/ButtonDatepicker.vue';

const emitter = useEmitter();
const store = useStore();
const $http = useHttp();

const iconMap = {
  onsite: ComputerDesktopIcon,
  email: EnvelopeIcon,
  discord: { c: CustomIcons, i: 'discord' },
  slack: { c: CustomIcons, i: 'slack' },
  webhook: { c: CustomIcons, i: 'webhook' },
  telegram: { c: CustomIcons, i: 'telegram' }
};

const getIconComponent = iconName => {
  return iconMap[iconName] || null;
};
const props = defineProps({
  alert: { type: Object, default: () => null },
  entity: { type: String },
  prefillAlertType: { type: Object, default: () => {} }
});

//GAS FEES
const gasFees = computed(() => {
  return store.getters.gasFees;
});

//PUBLISHERS
const publishers = ref(null);
async function fetchPublishers() {
  const response = await $http.get('/research_publishers');
  publishers.value = response.data.publishers.map(item => {
    return {
      id: item.id,
      label: item.name
    };
  });
}
onMounted(() => {
  fetchPublishers();
});

//NEWS FEEDS
const newsFeeds = ref(null);
async function fetchNewsFeeds() {
  const response = await $http.get('/news_feeds');
  // allowing on chain feed for alerts creation.
  let defaultFeeds = store.getters.defaultFeeds.filter(f => f != 'On-Chain Signal');
  newsFeeds.value = response.data.news_feeds
    .filter(x => !defaultFeeds.includes(x.name))
    .map(item => {
      return {
        id: item.id,
        label: item.name
      };
    })
    .filter(e => e.label != null);
}
onMounted(() => {
  fetchNewsFeeds();
});

//CREATE ALERT
const selectedAlertType = ref('Coin');
const duplicateName = ref(false);
const buttonDisabled = ref(false);
function emitDuplicateAlertError() {
  if (selectedAlertType.value == 'ResearchArticle') emitter.$emit('ResearchArticleForm', { duplicateAlert: true });
  else if (selectedAlertType.value == 'News') emitter.$emit('NewsForm', { duplicateAlert: true });
}
function goToAlerts() {
  router.visit('/alerts/active');
}
async function createAlertCall(alertData) {
  await $http
    .post('/alerts', alertData)
    .then(response => {
      if (response.data.message == 'Name taken') {
        duplicateName.value = true;
        setTimeout(function () {
          duplicateName.value = false;
        }, 3000);
      } else if (response.data.message == 'Alert already exists') {
        emitDuplicateAlertError();
      } else if (response.data.message == 'Invalid Address' && selectedAlertType.value == 'OnchainTransaction') {
        emitter.$emit('OnchainTransactionForm', { invalidAddress: true });
      } else {
        emitter.$emit('close:alerts-dialog');
        goToAlerts();
      }
      buttonDisabled.value = false;
    })
    .catch(() => {
      buttonDisabled.value = false;
    });
}
onMounted(() => {
  emitter.$on('update:alert-type', data => {
    selectedAlertType.value = data.alertType;
  });
  emitter.$on('clear:alert', () => {
    clearAlert();
  });
  if (props.alert || props.prefillAlertType) {
    selectedAlertType.value = props.prefillAlertType;
  }
});
onBeforeUnmount(() => {
  emitter.$off('update:alert-type');
});

//UPDATE ALERT
async function updateAlertCall(alertData, id) {
  await $http
    .patch(`/alerts/${id}`, alertData)
    .then(response => {
      if (response.data.message == 'Name taken') {
        duplicateName.value = true;
        setTimeout(function () {
          duplicateName.value = false;
        }, 3000);
      } else if (response.data.message == 'Alert already exists') {
        emitDuplicateAlertError();
      } else if (response.data.message == 'Invalid Address' && selectedAlertType.value == 'OnchainTransaction') {
        emitter.$emit('OnchainTransactionForm', { invalidAddress: true });
      } else {
        emitter.$emit('close:alerts-dialog');
        goToAlerts();
      }
      buttonDisabled.value = false;
    })
    .catch(() => {
      buttonDisabled.value = false;
    });
}

//ALERT NAME
const alertName = ref(props.alert && props.alert.name ? props.alert.name : '');
const coins = computed(() => store.getters.coins);
watch(
  () => props.alert,
  () => {
    if (props.alert?.id) {
      alertName.value = props.alert.name;
    }
  }
);
function symbol(s) {
  return {
    greater_than: '>',
    less_than: '<'
  }[s];
}
function formatAlertName(alertConfig) {
  if (!props.alert?.id) {
    let titleString = '';
    // COIN TITLE
    if (selectedAlertType.value == 'Coin') {
      if (
        !alertConfig ||
        [alertConfig.coins, alertConfig.watchlist, alertConfig.category, alertConfig.subcategory].every(v => v == null)
      ) {
        return '';
      } else if (alertConfig.coins) {
        titleString = alertConfig.coins.map(e => coins.value.filter(coin => coin.coin_uid == e)[0].name).join(', ');
      } else {
        if (alertConfig.watchlist) {
          titleString = alertConfig.watchlist.name;
        } else if (alertConfig.category) {
          const category = store.state.categories.coin.filter(e => e.id == alertConfig.category.id);
          titleString = category.length ? category[0].name : '';
        } else if (alertConfig.subcategory) {
          const sub_category = store.state.categories.coin_sub_categories.filter(
            e => e.id == alertConfig.subcategory.id
          );
          titleString = sub_category.length ? sub_category[0].name : '';
        }
        titleString = ['Coins from', titleString].join(' ');
      }
      const metrics_string = alertConfig.metrics
        .map(e => {
          const attrs = [
            e.name,
            symbol(e.comparison),
            e.value < 0 ? formatNumber(e.value) : formatSmallNumbers(e.value)
          ];
          if (!(attrs.includes('') || attrs.includes(undefined))) {
            return attrs;
          }
        })
        .filter(e => e != undefined)
        .map(e => e.join(' '))
        .join(' and ');
      const join_str = ![titleString, metrics_string].includes('') ? ' Where ' : ' ';

      titleString = [titleString, metrics_string].join(join_str);
    }
    // NEWS TITLE
    else if (selectedAlertType.value == 'News' && alertConfig.news_feed_id) {
      titleString = newsFeeds.value.filter(e => e.id == alertConfig.news_feed_id)[0].label;
    }
    // SEC TITLE
    else if (selectedAlertType.value == 'SEC' && alertConfig.keywords?.length) {
      titleString = `SEC Form${alertConfig.keywords?.length > 1 ? 's' : ''}: ${alertConfig.keywords.join(', ')}`;
    }
    //Onchain Transaction Title
    else if (selectedAlertType.value == 'OnchainTransaction') {
      titleString = `(${titleize(alertConfig.chain)})`;
      let typeMapping = {
        coin_transfer_all: 'Coin Transfers: All',
        coin_transfer_to: 'Coin Transfers: To',
        coin_transfer_from: 'Coin Transfers: From',
        dex_trade: 'DEX Swaps'
      };
      titleString = titleString + ' ' + typeMapping[alertConfig.type];
      if (alertConfig.protocols?.length && alertConfig.type == 'dex_trade') {
        let protocols = alertConfig.protocols.join(', ');
        titleString += ' where Protocols = [' + protocols + ']';
      } else if (alertConfig.addresses?.address) {
        titleString += ' where Address = ' + alertConfig.addresses.address;
      } else if (alertConfig.addresses?.address_tags?.length) {
        let addresses = alertConfig.addresses.address_tags.map(x => x.label).join(', ');
        titleString += ' where Addresses = [' + addresses + ']';
      } else if (alertConfig.addresses?.folders?.length) {
        let folders = alertConfig.addresses.folders.map(x => x.label).join(', ');
        titleString += ' where Folders = [' + folders + ']';
      }
      if (alertConfig.value && alertConfig.value != '') {
        titleString += ' and Value > ' + alertConfig.value;
      }
      if (alertConfig.coins?.length > 0) {
        titleString +=
          ' for ' + alertConfig.coins.map(e => coins.value.filter(coin => coin.coin_uid == e)[0].name).join(', ');
      } else if (alertConfig.watchlist?.id) {
        titleString += ' for Coins from ' + alertConfig.watchlist.name;
      } else if (alertConfig.category?.id) {
        const category = store.state.categories.coin.filter(e => e.id == alertConfig.category.id);
        if (category.length) titleString += ' for Coins from ' + category[0].name;
      } else if (alertConfig.subcategory?.id) {
        const sub_category = store.state.categories.coin_sub_categories.filter(e => e.id == alertConfig.subcategory.id);
        if (sub_category.length) titleString += ' for Coins from ' + sub_category[0].name;
      } else if (alertConfig.collections?.length > 0) {
        titleString += ' for NFTs from ' + alertConfig.collections.join(', ') + ' Collections';
      }
    }
    // GAS TITLE
    else if (selectedAlertType.value == 'Gas') {
      let metrics_string;
      if (alertConfig.mode) {
        titleString = gasFees.value[alertConfig.mode].description;
      }
      if (alertConfig.metrics) {
        metrics_string = alertConfig.metrics
          .filter(e => ![e.speed, e.comparison, e.value].includes(null))
          .map(e => [e.speed, symbol(e.comparison), e.value].join(' '))
          .join(' and ');
      }
      const join_str = ![titleString, metrics_string].includes('') ? ' Where ' : ' ';
      titleString = [titleString, titleize(metrics_string)].join(join_str);
    }
    // RESEARCH TITLE
    else if (selectedAlertType.value == 'ResearchArticle') {
      if (alertConfig.research_publisher_id) {
        titleString = publishers.value.filter(e => e.id == alertConfig.research_publisher_id)[0].label;
        titleString = `Research from ${titleString}`;
      }
    }
    // UNLOCKS TITLE
    else if (selectedAlertType.value == 'Unlock') {
      if (alertConfig.coins?.length > 0) {
        titleString = alertConfig.coins.map(e => coins.value.filter(coin => coin.coin_uid == e)[0].name).join(', ');
      } else if (alertConfig.watchlist) {
        titleString = alertConfig.watchlist.name;
      } else if (alertConfig.category) {
        const category = store.state.categories.coin.filter(e => e.id == alertConfig.category.id);
        titleString = category.length ? category[0].name : '';
      } else if (alertConfig.subcategory) {
        const sub_category = store.state.categories.coin_sub_categories.filter(e => e.id == alertConfig.subcategory.id);
        titleString = sub_category.length ? sub_category[0].name : '';
        titleString = ['Coins from', titleString].join(' ');
      }
      if (alertConfig.filters) {
        if (alertConfig.filters.timeframe) {
          titleString += `${
            alertConfig.coins?.length > 0 || alertConfig.watchlist || alertConfig.category || alertConfig.subcategory
              ? ' in'
              : ' In'
          }`;
          titleString += ` the next ${alertConfig.filters.timeframe} days where events `;
        }
        if (alertConfig.filters.threshold != '0.00') {
          titleString += `is at least ${alertConfig.filters.threshold} `;
        }
        if (alertConfig.filters.thresholdMetric) {
          titleString += `of ${alertConfig.filters.thresholdMetric.replace(/(?:_| |\b)(\w)/g, function ($1) {
            return $1.toUpperCase().replace('_', ' ');
          })}`;
        }
        if (alertConfig.filters?.types?.length > 0) {
          titleString += `${alertConfig.filters?.entities?.length == 0 ? 'and' : ' '} types involve ${
            alertConfig.filters.types
          }`;
        }
        if (alertConfig.filters.entities?.length > 0) {
          titleString += ` and entities involve ${alertConfig.filters.entities}`;
        }
      }
      titleString = [titleString].join();
    }
    // Pair TITLE
    else if (selectedAlertType.value == 'Pair') {
      if (alertConfig.metric && alertConfig?.pair) {
        titleString = `${alertConfig?.pair} ${alertConfig.metric.name || titleize(alertConfig.metric.id)}`;
        titleString += ` ${symbol(alertConfig.metric.comparison)} ${alertConfig.metric.value || 0}`;
      }
    }
    alertName.value = titleString;
  }
}

//WEBHOOKS
const showModal = ref(false);
const messagingPermission = computed(() => store.state.streamClient);
const alertDurationsMap = { always: 'Ongoing', once: 'Once' };
const duration = ref('Ongoing');
const webhooks = ref([
  { id: 0, channel: 'onsite', name: 'On Site', checked: false },
  {
    id: 1,
    channel: 'sigdev_messenger',
    mode: 'messenger',
    name: 'SigDev Messenger',
    checked: false,
    chat_name: '',
    chat_id: ''
  }
]);
const filteredWebhooks = computed(() => webhooks.value.filter(x => x.id == 1 || x.channel != 'sigdev_messenger'));
const messengerWebhook = computed(() => webhooks.value.find(x => x.id == 1));

watch([() => props.entity, () => props.alert], () => {
  if (!props.alert?.id) {
    alertName.value = '';
    expiration_date.value = '';
    duration.value = 'Ongoing';
    webhooks.value = webhooks.value.map(x => {
      x.checked = false;
      return x;
    });
  } else if (props.alert) {
    duration.value = alertDurationsMap[props.alert.duration];
    expiration_date.value = DateTime.fromISO(props.alert.expiration_date).toFormat('yyyy-MM-dd');

    //CHECK ONSITE
    const onsiteWebhookIndex = webhooks.value.findIndex(x => x.id == 0);
    webhooks.value[onsiteWebhookIndex].checked = props.alert.deliver_onsite;

    //CHECK MESSENGER
    const msngrWebhookIndex = webhooks.value.findIndex(x => x.id == 1);
    webhooks.value[msngrWebhookIndex].checked = props.alert.deliver_on_messenger;
  }
});
async function getWebhooks() {
  let response = await $http.get('/data/webhooks');
  let messengerIDs = response.data.filter(x => x.channel == 'sigdev_messenger').map(x => x.id);
  let activeDeliveryMethods =
    props.alert && props.alert.delivery_methods
      ? props.alert.delivery_methods.filter(x => !messengerIDs.includes(x))
      : [];
  const newWebhooks = response.data
    .filter(x => !webhooks.value.find(w => w.id == x.id))
    .map(x => {
      x.checked = activeDeliveryMethods.length ? activeDeliveryMethods.includes(x.id) : false;
      return x;
    });
  webhooks.value = [...newWebhooks, ...webhooks.value];
}
onMounted(() => {
  function getAllWebhooks(webhook_id) {
    getWebhooks().then(() => {
      if (props.alert?.deliver_on_messenger) {
        let selectedChat = webhooks.value.find(
          x => x.channel == 'sigdev_messenger' && props.alert.delivery_methods.includes(x.id)
        );
        let defaultMessengerOption = webhooks.value.findIndex(x => x.id == 1);
        selectedMessengerChat.value = { id: selectedChat.link, name: selectedChat.name };
        webhooks.value[defaultMessengerOption].checked = true;
      }
      if (webhook_id) {
        webhooks.value = webhooks.value.map(x => {
          if (x.id === webhook_id) return { ...x, checked: true };
          else return x;
        });
      }
    });
  }
  getAllWebhooks();
  emitter.$on('webhook-added', webhook_id => getAllWebhooks(webhook_id));
});
onBeforeUnmount(() => {
  emitter.$off('webhook-added');
});

const selectMessengerChat = data => {
  if (selectedMessengerChat.value && selectedMessengerChat.value.id === data.id) {
    selectedMessengerChat.value = null;
  } else {
    selectedMessengerChat.value = data;
  }
};

//SAVE ALERT
const messengerDefaultOption = computed(() => webhooks.value.find(x => x.id == 1));
const selectedMessengerChat = ref(null);
const required = ref({ method: false, name: false });
const config = ref(props.alert ? props.alert.config : null);
const expiration_date = ref('');
const deliveryTimeframe = ref(null);
const deliveryTime = ref(null);
watch(
  () => messengerDefaultOption.value.checked,
  currentStatus => {
    if (!currentStatus) {
      selectedMessengerChat.value = null;
    }
  }
);
function validation() {
  let validate = true;
  if (messengerDefaultOption.value.checked) {
    if (!selectedMessengerChat.value) {
      required.value.method = true;
      validate = false;
    }
  } else if (!filteredWebhooks.value.filter(x => x.checked).length) {
    required.value.method = true;
    validate = false;
  }
  if (!alertName.value) {
    required.value.name = true;
    validate = false;
  }
  setTimeout(function () {
    required.value.method = false;
    required.value.name = false;
  }, 5000);
  let error = {};
  let data = config.value;
  if (data == null) {
    error.alertType = true;
    validate = false;
  } else if (selectedAlertType.value == 'Coin') {
    if (!data || [data.coins, data.watchlist, data.category, data.subcategory].every(v => v == null)) {
      error.alertType = true;
      validate = false;
    }
    error.metrics = [];
    data.metrics.forEach((m, i) => {
      if ([m.id, m.comparison, m.value].some(a => a == null || a == '')) {
        error.metrics.push(i);
        validate = false;
      }
    });
  } else if (selectedAlertType.value == 'OnchainTransaction') {
    if (
      ((data.type == 'dex_trade' && !data.protocols?.length) || data.type != 'dex_trade') &&
      !data.addresses?.address &&
      !data.addresses?.address_tags?.length &&
      !data.addresses.folders?.length &&
      [data.coins, data.watchlist, data.category, data.subcategory].every(
        v => !v || (Array.isArray(v) && !v.length) || (typeof v == 'object' && !Array.isArray(v) && !v.id)
      )
    ) {
      error.alertType = true;
      validate = false;
    }
  } else if (selectedAlertType.value == 'Gas') {
    if (!data.mode) {
      error.alertType = true;
      validate = false;
    }
    error.metrics = [];
    data.metrics.forEach((m, i) => {
      if ([m.comparison, m.value].some(a => a == null)) {
        error.metrics.push(i);
        validate = false;
      }
    });
  } else if (selectedAlertType.value == 'SEC') {
    if (!data.keywords.length > 0) {
      error.alertType = true;
      validate = false;
    }
  } else if (selectedAlertType.value == 'News') {
    if (!data.news_feed_id) {
      error.alertType = true;
      validate = false;
    }
  } else if (selectedAlertType.value == 'ResearchArticle') {
    if (!data.research_publisher_id) {
      error.alertType = true;
      validate = false;
    }
  } else if (selectedAlertType.value == 'Calendar') {
    if (!data.news_feed_ids.length > 0 || !deliveryTime.value) {
      error.alertType = !data.news_feed_ids.length > 0;
      error.customTime = !deliveryTime.value;
      validate = false;
    }
  } else if (selectedAlertType.value == 'Unlock') {
    if (!data.filters.timeframe) {
      error.alertType = true;
      validate = false;
    }
  } else if (selectedAlertType.value == 'Pair') {
    error.metrics = [];
    const m = data.metric;
    if ([m.id, m.comparison, m.value].some(a => a == null || a == '')) {
      validate = false;
    }
  }
  if (!isEmpty(error)) {
    emitter.$emit(`${selectedAlertType.value.replace(/\s+/g, '')}Form`, error);
  }
  return validate;
}
function clearAlert() {
  webhooks.value = webhooks.value.map(x => {
    x.checked = false;
    return x;
  });
  duration.value = 'Ongoing';
  expiration_date.value = '';
  alertName.value = '';
}
async function saveAlert() {
  const alertDurationsMap = { Ongoing: 'always', Once: 'once' };
  if (validation()) {
    buttonDisabled.value = true;

    let alertData = {
      name: alertName.value,
      expiration_date: expiration_date.value,
      delivery_methods: webhooks.value
        .filter(x => x.checked && x.id != 0 && x.channel != 'sigdev_messenger')
        .map(x => x.id),
      deliver_on_messenger: messengerWebhook.value.checked,
      chat_id: selectedMessengerChat.value?.id,
      chat_name: selectedMessengerChat.value?.name,
      duration: alertDurationsMap[duration.value],
      entity: selectedAlertType.value,
      config: config.value,
      delivery_timeframe: deliveryTimeframe.value,
      delivery_time: deliveryTime.value,
      deliver_onsite: webhooks.value.find(x => x.checked && x.id == 0) ? true : false
    };
    if (alertData.config?.metrics) {
      alertData.config.metrics = alertData.config.metrics.map(
        // eslint-disable-next-line no-unused-vars
        ({ required, mainMetric, ...keepAttrs }) => keepAttrs
      );
    }
    if (props.alert && props.alert.id) {
      await updateAlertCall(alertData, props.alert.id);
    } else {
      await createAlertCall(alertData);
    }
  }
}
onMounted(() => {
  emitter.$on('save:alert', () => {
    Promise.all([saveAlert()]).then(() => {
      emitter.$emit('enable:alert-button');
    });
  });
  emitter.$on('config:alert', data => {
    config.value = data.config;
    deliveryTimeframe.value = data.deliveryTimeframe;
    deliveryTime.value = data.deliveryTime;
    if (!props.alert || !props.alert.name) formatAlertName(data.config);
  });
});
onBeforeUnmount(() => {
  emitter.$off('save:alert');
  emitter.$off('config:alert');
});
</script>
